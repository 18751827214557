<template>
<q-form ref="editForm">
  <c-card title="상세" class="cardClassDetailForm" style="padding-bottom:10px!important">
    <template slot="card-button">
      <q-btn-group outline >
        <c-update-btn 
          name="updateBtn"
          :data="param.updateBtnData"
          :btnEditable="btnEditable"
          :flagCondition="flagCondition"
          @back="back"
        />
        <c-btn 
          v-show="editable&&!deleteDisabled&&isOld"
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="issueCause"
          mappingType="PUT"
          label="LBLCOMPLETE" 
          icon="check"
          @beforeAction="completePlan"
          @btnCallback="completePlanCallback" />
      </q-btn-group>
    </template>
    <template slot="card-detail">
      <div class="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <c-label-text title="제목" :value="issueCause.issuesTitle"></c-label-text>
      </div>
      <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2">
        <c-label-text title="대상년도" :value="issueCause.issuesYear"></c-label-text>
      </div>
      <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2">
        <c-label-text title="구분" :value="issueCause.issuesClassName"></c-label-text>
      </div>
      <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2">
        <c-label-text title="작성자" :value="issueCause.issuesDeptName + '/' + issueCause.issuesUserName"></c-label-text>
      </div>
      <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2">
        <c-label-text title="작성일" :value="issueCause.issuesDate"></c-label-text>
      </div>
      
    </template>
  </c-card>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <c-table
      ref="table"
      title="평가대상 목록"
      :columns="grid.columns"
      :gridHeight="gridHeight"
      :data="issueCause.resultList"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="issuesResultId"
      @table-data-change="tableDataChange"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'issuesTypeName'">
          {{props.row.issuesTypeName}}
          <div v-if="editable">
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                icon="add"
                color="red-6"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props)">
                <q-tooltip>
                  {{'평가대상구분별 평가대상 추가'}}
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </div>
        </template>
        <template v-if="col.name === 'actionCheckFlag'">
          <q-checkbox
            dense
            color="orange-custom"
            class="tableCheckBox"
            :disable="disabled || props.row['isActionCheckDisable']"
            true-value="Y"
            false-value="N"
            v-model="props.row['actionCheckFlag']"
            @input="val => checkDataChange(props)">
          </q-checkbox>
        </template>
      </template>
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable&&!disabled" 
            :showLoading="false" 
            label="법령 추가" 
            icon="add" 
            @btnClicked="addLaw" />
          <c-btn 
            v-if="editable&&!disabled" 
            :showLoading="false" 
            label="평가대상 추가" 
            icon="add" 
            @btnClicked="addTarget" />
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="issueCause.resultList"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveData"
            @btnCallback="saveCallback" />
          <c-btn 
            v-if="editable&&!disabled" 
            label="삭제" 
            icon="remove" 
            @btnClicked="deleteTarget" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
  <c-dialog :param="popupOptions"></c-dialog>
</q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'issueCause',
  props: {
    param: {
      type: Object,
      default: () => ({
        issuesId: '',
        issuesStepCd: '',
        updateBtnData: {
          title: '',
          flag: false,
          research: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      splitter: 5,
      issueCause: {
        issuesId: '',
        issuesTitle: '',
        issuesClassCd: null,
        issuesClassName: '',
        issuesDeptCd: '',
        issuesDeptName: '',
        issuesUserId: '',
        issuesUserName: '',
        issuesDate: '',
        issuesYear: '',
        plantCd: '',
        issuesStepCd: 'IIS0000001',
        resultList: [],
        deleteResultList: [],
      },
      grid: {
        columns: [
          {
            name: 'issuesTypeName',
            field: 'issuesTypeName',
            label: '평가대상구분',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
            sortable: false,
          },
          {
            required: true,
            name: 'issuesName',
            field: 'issuesName',
            label: '평가대상명',
            align: 'left',
            type: 'text',
            style: 'width:100px',
            sortable: false,
          },
          {
            required: true,
            name: 'issuesContents',
            field: 'issuesContents',
            label: '이슈사항',
            align: 'left',
            type: 'textarea',
            style: 'width:200px',
            sortable: false,
          },
          {
            required: true,
            name: 'issuesRisk',
            field: 'issuesRisk',
            label: '리스크',
            align: 'left',
            type: 'textarea',
            style: 'width:200px',
            sortable: false,
          },
          {
            required: true,
            name: 'issuesCause',
            field: 'issuesCause',
            label: '원인',
            align: 'left',
            type: 'textarea',
            style: 'width:200px',
            sortable: false,
          },
          {
            required: true,
            name: 'currentManagementMeasures',
            field: 'currentManagementMeasures',
            label: '현재관리대책',
            align: 'left',
            type: 'textarea',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'assessment',
            field: 'assessment',
            label: '리스크 평가',
            align: 'center',
            child: [
              {
                name: 'possibility',
                field: 'possibility',
                label: '가능성',
                align: 'right',
                type: 'number',
                style: 'width:60px',
                sortable: false,
              },
              {
                name: 'severity',
                field: 'severity',
                label: '심각성',
                align: 'right',
                type: 'number',
                style: 'width:60px',
                sortable: false,
              },
              {
                name: 'risks',
                field: 'risks',
                label: '위험성',
                align: 'right',
                type: 'number',
                style: 'width:60px',
                sortable: false,
              },
            ]
          },
          {
            name: 'actionCheckFlag',
            field: 'actionCheckFlag',
            align: 'center',
            label: '조치계획여부',
            type: 'custom',
            true: 'Y',
            false: 'N',
            style: 'width:80px',
            sortable: false,
          },
        ],
      },
      editable: true,
      isSave: false,
      mappingType: 'POST',
      saveUrl: '',
      detailUrl: '',
      completeUrl: '',
      deleteUrl: '',
      isComplete: false,
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    gridHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 160
      return numHeight + 'px';
    },
    isOld() {
      return Boolean(this.issueCause.issuesId)
    },
    deleteDisabled() {
      return this.issueCause.issuesStepCd !== 'IIS0000005'
    },
    disabled() {
      return this.issueCause.issuesStepCd !== 'IIS0000005' && !this.param.updateBtnData.flag
    },
    btnEditable() {
      return this.editable && (this.issueCause.issuesStepCd === 'IIS0000010' && Boolean(!this.issueCause.sysApprovalRequestId)) && Boolean(this.issueCause.issuesId) 
    },
    flagCondition() {
      return this.issueCause.issuesStepCd === 'IIS0000005'
    },
  },
  watch: {
    'param.updateBtnData.research'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.iei.issue.get.url;
      this.completeUrl = transactionConfig.sai.iei.issue.update.url
      this.saveUrl = transactionConfig.sai.iei.assessment.save.url
      this.deleteUrl = transactionConfig.sai.iei.assessment.delete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.issuesId) {
        this.$http.url = this.$format(this.detailUrl, this.param.issuesId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.issueCause = _result.data;
        },);
      } 
    },
    saveData() {
      if (this.$comm.validTable(this.grid.columns, this.issueCause.resultList)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?', 
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    completePlan() {
      if (this.$comm.validTable(this.grid.columns, this.issueCause.resultList)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGCOMPLETE', // 완료하시겠습니까?, // 완료하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.issueCause.chgUserId = this.$store.getters.user.userId
            this.issueCause.issuesStepCd = 'IIS0000010'

            this.isComplete = !this.isComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completePlanCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getDetail();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: this.issueCause.issuesStepCd
      })
    },
    addLaw() {
      this.popupOptions.title = '법령 검색'; 
      this.popupOptions.param = {
        type: 'multiple',
        targetDeptCd: this.issueCause.issuesDeptCd
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/law/register/lawRegisterPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLawPopup;
    },
    closeLawPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEachRight(data, item => {
          this.issueCause.resultList.splice(0, 0, {
            issuesResultId: uid(),
            issuesId: this.param.issuesId,
            issuesTypeCd: 'IIT0000020',
            issuesTypeName: '준수업무',
            issuesName: item.lawName + '/' + item.relevantClause,
            issuesContents: '',
            issuesCause: '',
            issuesRisk: '',
            currentManagementMeasures: '',
            possibility: 0,
            severity: 0,
            risks: 0,
            actionPlan: '',
            actionCheckFlag: 'N',
            sortOrder: item.sortOrder,
            measurementEffectivenessAssessment: '',
            editFlag: 'C', regUserId: this.$store.getters.user.userId,
          })
        })
      }
    },
    addTarget() {
      this.popupOptions.title = '평가대상 검색'; // 평가대상 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.$store.getters.user.plantCd,
        issuesClassCd: this.issueCause.issuesClassCd
      };
      this.popupOptions.target = () => import(`${'./internalExternalIssuesItemPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTargetPopup;
    },
    closeTargetPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.issueCause.resultList, _item => {
            return item.issuesTypeName === _item.issuesTypeName && item.issuesName === _item.issuesName
          }) === -1) {
            this.issueCause.resultList.splice(0, 0, {
              issuesResultId: uid(),
              issuesId: this.param.issuesId,
              issuesTypeCd: item.issuesTypeCd,
              issuesTypeName: item.issuesTypeName,
              issuesName: item.issuesName,
              issuesCause: '',
              issuesRisk: '',
              currentManagementMeasures: '',
              possibility: '',
              severity: '',
              risks: '',
              actionPlan: '',
              actionCheckFlag: 'N',
              sortOrder: item.sortOrder,
              measurementEffectivenessAssessment: '',
              editFlag: 'C', regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    deleteTarget() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.issueCause.resultList = this.$_.reject(this.issueCause.resultList, item);
              })
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    tableDataChange(props, col) {
      if (col.name === 'possibility' || col.name === 'severity') {
        this.$set(props.row, 'risks', Number(props.row['possibility']) + Number(props.row['severity']))
      }
      
      if (props.row['risks'] >= 6) {
        this.$set(props.row, 'actionCheckFlag', 'Y');
        this.$set(props.row, 'isActionCheckDisable', true)
      }
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.getDetail();
    },
    checkDataChange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props) {
      this.issueCause.resultList.splice(props.rowIndex, 0, {
        issuesResultId: uid(),
        issuesId: this.param.issuesId,
        issuesTypeCd: props.row.issuesTypeCd,
        issuesTypeName: props.row.issuesTypeName,
        issuesName: '',
        issuesCause: '',
        issuesContents: '',
        issuesRisk: '',
        currentManagementMeasures: '',
        possibility: 0,
        severity: 0,
        risks: 0,
        actionPlan: '',
        measurementEffectivenessAssessment: '',
        editFlag: 'C', 
        actionCheckFlag: 'N',
        sortOrder: props.row.sortOrder + 1,
        regUserId: this.$store.getters.user.userId,
      })
    },
  }
};
</script>
